import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import { useState, useEffect } from "react";
import axios from 'axios';
import * as fcl from "@onflow/fcl";
// This module contains country information including 2 and 3 character ISO codes
import country from 'country-list-js';

// import SvgDownloadPopup from './SvgDownloadPopup';
import FileUpload from './FileUpload/FileUpload';
import breedsData from './breeds.json';

function CertificateForm() {
  // const [certificateData, setCertificateData] = useState({
  //   dogsImage : '',
  //   dogsName : ''
  // });
  // const [showPopup, setShowPopup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [user, setUser] = useState({ loggedIn: false, addr: undefined });
  const [formData, setFormData] = useState({
    dogsName: '',
    email: '',
    country: 'United States',
    breedName: 'Mutt',
    dogsImage: '',
    addr: '',
    chipIdentifier : '',
    fbUsername : '',
    lastName: '',
    firstName: ''
  });

	useEffect(() => {
        fcl.currentUser.subscribe(setUser);
        setFormData((prevFormData) => ({ ...prevFormData, addr: user.addr }));
  }, [user.addr]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'breedName') {
      const breedLink = breedsData.breeds.filter((breed) => breed.name === value)
      if (breedLink.length) {
        setFormData((prevFormData) => ({ ...prevFormData, 'breedAmLink': breedLink[0].url }));
      }
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    setButtonDisabled(true)
    console.log(formData)
    event.preventDefault();
    try {
      const res = await axios.post('https://mydogsnft.com/wp-json/nft/v1/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      });
      console.log(res)
      setButtonDisabled(false)
      // setShowPopup(true)
      // setCertificateData((prevFormData) => ({ ...prevFormData, 
      //   dogsImage: res.data.data.dogs_image,
      //   dogsName: formData.dogsName
      // }))
      alert("Order Created")
    } catch (error) {
      console.error(error.message);
      setButtonDisabled(false)
    }
  };

  // Countries
  let country_names = country.names();
  const countries = country_names.sort().map(item => 
    (item === 'Russia') ? 
    <option value={item} disabled>{item}</option> :
    <option value={item}>{item}</option>
  )

  // Breeds
  const breeds = breedsData.breeds.map(item => <option value={item.name}>{item.name}</option>)

  return(
    <>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={10} xl={7}>
            <Form className='text-start' onSubmit={handleSubmit}>
              <h1 className='page-title text-center mb-4'>Certificate Request Form</h1>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='FirstName'>First name <span style={{color: '#b94a48'}}>*</span></Form.Label>
                  <Form.Control required type="text" placeholder="" id='FirstName' name='firstName' onChange={handleChange} />
                </Col>
                <Col>
                  <Form.Label htmlFor='LastName'>Last name <span style={{color: '#b94a48'}}>*</span></Form.Label>
                  <Form.Control required type="text" placeholder="" id="LastName" name='lastName' onChange={handleChange} />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='country'>Country <span style={{color: '#b94a48'}}>*</span></Form.Label>
                  <Form.Select defaultValue='United States' aria-label="Default select example" id="country" name='country' onChange={handleChange}>{countries}
                  </Form.Select>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='DogsName'>Dogs Name <span style={{color: '#b94a48'}}>*</span></Form.Label>
                  <Form.Control required type="text" placeholder="" id='dogsName' name='dogsName' onChange={handleChange} />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='email'>Your Email <span style={{color: '#b94a48'}}>*</span></Form.Label>
                  <Form.Control required type="email" name='email' placeholder="" id='YourEmail' onChange={handleChange} />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='fbUsername'>Facebook Username</Form.Label>
                  <Form.Control type="text" placeholder="" id='fbUsername' name='fbUsername' onChange={handleChange} />
                  <Form.Text className="text-muted">
                    If you do not know your FB username enter FB Profile ID, <span style={{color: 'red'}}>Required for free NFT Certificate</span>
                  </Form.Text>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='breedName'>Breed Name <span style={{color: '#b94a48'}}>*</span></Form.Label>
                    <Form.Select required defaultValue='Mutt' aria-label="Default select example" id="breedName" name='breedName' onChange={handleChange}>
                      {breeds}
                    </Form.Select>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label htmlFor='chipIdentifier'>Chip Identifier</Form.Label>
                  <Form.Control required type="text" placeholder="" id='chipIdentifier' name='chipIdentifier' onChange={handleChange} />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId="formFile" className='text-center'>
                    <Form.Label>Dog's Photo <span style={{color: '#b94a48'}}>*</span></Form.Label>
                    {/* <Form.Control required type="file" name='dogsImage' onChange={handleFileChange} /> */}
                    <FileUpload setFormData={setFormData}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3 text-center'>
                <Col>
                  <Button disabled={buttonDisabled} variant="primary" type="submit">Submit and Receive Free NFT</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <SvgDownloadPopup certificateData={certificateData} showPopup={showPopup} setShowPopup={setShowPopup} /> */}
    </>
  )
}

export default CertificateForm;