import "./FileUpload.css";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useRef } from "react";

function FileUpload({ setFormData }) {
  const [image, setImage] = useState();
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [progressBar, setProgressBar] = useState(0);
  const [labelFile, setLabelFile] = useState({});
  const [fileInfoBox, setFileInfoBox] = useState({display: 'none'})

  const inputFile = useRef();

  const handleFile = (event) => {
    const file = event.target.files[0];
    const name =  event.target.name

    setFormData((prevFormData) => ({ ...prevFormData, [name]: file }));

    setLabelFile({display: 'none'});
    setFileInfoBox({display: 'block'});

    setFileName(file.name);

    if(file.size<1024) {
      setFileSize(file.size + " b")
    } else if (file.size < 1048576) {
      setFileSize(Math.round(file.size/1024) + " Kb")
    } else {
      setFileSize(Math.round(file.size/1048576) + " Mb")
    }

    const formdata = new FormData()
    setImage(URL.createObjectURL(file))
    formdata.append('file', file)
    setProgressBar(100)

    // axios.post('url', formdata, {
    //   headers: {
    //     "Content-Type" : "multipart/form-data"
    //   },
    //   onUploadProgress: event => {
    //     setProgressBar(Math.round(100 * event.loaded) /event.total)
    //   }
    // }).then(res => setImage(URL.createObjectURL(file)))
    // .catch(err => console.log(err));
  }

  const handleClose = () => {
    setLabelFile({display: 'block'});
    setFileInfoBox({display: 'none'});
    inputFile.current.value = null;
  }
  
  return(
    <>
      <div className="upload-file-box">
        <div className="file-wrap" style={labelFile}>
          <input required name='dogsImage' ref={inputFile} type="file" onChange={handleFile}/>
          <span className="upload-file-icon"></span>
          Dog Photos should be just the face of the dog
          looking directly at the camera
          {/* Upload Photo of
          <br/>
          Your Dog */}
          <span className="max-size">Maximum file size: <br/> 268.44MB</span>
        </div>
        <div className="holder" style={fileInfoBox}>
          <img src={image} className="img" alt="My dog"/>
          <div className="inner">
            <span className="file-name">{fileName}</span>
            <div className="file-size">{fileSize}</div>
            <span className="btn-file-close" onClick={handleClose}>+</span>
            <div className="progress">
              <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" arial-label="progressbar" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{width: `${progressBar}%`}}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FileUpload;