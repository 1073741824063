import {Form} from 'react-bootstrap';
import {useState} from 'react';

const SearchTableBox = ({onChange, dropVisible, data, onClick, tagName}) => {
  const [dropName, setDropName] = useState('');

  const handleSearch = e => {
    let value = e.target.value.trim();
    let column = "";
    switch (e.target.name) {
      case 'email':
        column = '&column=email';
        onChange(value, column);
        (value !== "") ? setDropName('email') : setDropName('')
        break;
      case 'dogsName':
        column = '&column=dogs_name';
        onChange(value, column);
        (value !== "") ? setDropName('dogsName') : setDropName('')
        break;
      case 'chipId':
        column = '&column=chip_identifier';
        onChange(value, column);
        (value !== "") ? setDropName('chipId') : setDropName('')
        break;
      case 'fbUserName':
        column = '&column=fb_username';
        onChange(value, column);
        (value !== "") ? setDropName('fbUserName') : setDropName('')
        break;
      case 'lastName':
        column = '&column=last_name';
        onChange(value, column);
        (value !== "") ? setDropName('lastName') : setDropName('')
        break;
      case 'firstName':
        column = '&column=first_name';
        onChange(value, column);
        (value !== "") ? setDropName('firstName') : setDropName('')
        break;
      case 'country':
        column = '&column=country';
        onChange(value, column);
        (value !== "") ? setDropName('country') : setDropName('')
        break;
    }
  }

  const handleClickSearch = e => {
    let searchText = e.target.innerText;
    onClick(searchText);
    document.getElementById(tagName).value = searchText;
  }

  return(
    <div className="input-holder">
      <Form.Control
        type="text"
        placeholder=""
        autoComplete="off"
        id={tagName}
        name={tagName}
        onChange={handleSearch}
      />
      <ul 
        id={tagName + '1'}
        style={
          (dropName === tagName) && (dropVisible === true)
            ? {display: 'block'}
            : {display: 'none'}
        }
      >
        {
          data.map((item, i) => {
            if (item !== 'no result') {
                return <li className='link' onClick={handleClickSearch} key={item}>{item}</li>
            } else {
              return <li key={item}>{item}</li>
            }
          })
        }
      </ul>
    </div>
  )
}

export default SearchTableBox