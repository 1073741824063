import DataTable from '../components/DataTable/DataTable';

function TablePage() {
  return(
    <>
      <h1 className='page-title text-center mb-4'>NFT DB</h1>
      <dl className="aditinal-info text-left">
        <dt>Date:</dt><dd>2024-02-18</dd>
        <dt>Time:</dt><dd>19:20:25</dd>
        <dt>Number of NFT’s:</dt><dd>3</dd>
      </dl>
      <DataTable />
    </>
  )
}

export default TablePage