import Table from 'react-bootstrap/Table';
import {Form} from 'react-bootstrap';
import breedsData from '../breeds.json';
import './BreedsTable.css'


function BreedsTable() {
  console.log(breedsData.breeds.length)
  return(
    <div className="container-fluid table-section">
      <Form>
        <Table striped bordered hover responsive className="table breeds-table">
          <thead>
            <tr>
              <th>Breed</th>
              <th>Amazon Link</th>
            </tr>
          </thead>
          <tbody>
            {breedsData.breeds.map(item => {
              return(
                <tr key={item.name}>
                  <td>{item.name}</td>
                  <td><a href={item.url}>{item.url}</a></td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Form>
    </div>
  )
}

export default BreedsTable