import * as fcl from "@onflow/fcl";
import axios from 'axios';
import { useState, useEffect } from "react";

function MintButton({id, setUpadteData}) {
    const [user, setUser] = useState({ loggedIn: false, addr: undefined });
    const [mint, setMInt] = useState(false);

    useEffect(() => {
        fcl.currentUser.subscribe(setUser);
    }, [user.addr]);

    const updateData = async (id, address) => {
        const apiURL = `https://mydogsnft.com/wp-json/nft/v1/update`
        const formData = {
            address, id
        }

        await axios.post(apiURL, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then((data) => {
          console.log(data)
        }).catch((error) => {
            console.error("Network error:", error);
        });
    }

    async function mintNFT(e) {
      e.preventDefault();
      if (user.addr) {
        try {
          await updateData(id, user.addr)
          window.alert("NFT Minted! ")
          setMInt(false)
          setUpadteData(Math.random())


        } catch (error) {
          console.log("err", error);
        }
      } else { alert('First you need to login') }
    }
  
    return (
      (!mint) ? <button className="btn btn-sm btn-primary text-nowrap" onClick={(e) => mintNFT(e)}>Create NFT</button> :
               <button className="btn btn-sm btn-primary text-nowrap">Loading ...</button>
    )
  }
  
  export default MintButton;