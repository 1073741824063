const fcl = require("@onflow/fcl");

// const DAPP_ID = '532bbf07-3006-4490-9d20-8502777272d9'
// const USER_EMAIL = "arsen.manukyan@zojax.com";

// fcl.config({
//   "app.detail.title": "Flow Mint Page Tutorial", // this adds a custom name to our wallet
//   "app.detail.icon": "https://mydogsnft.com/wp-content/uploads/2023/11/Dog2.png",
//   "accessNode.api": "https://rest-mainnet.onflow.org", // connect to Flow mainnet
//   "discovery.wallet": `https://wallet-v2.blocto.app/${DAPP_ID}/flow/authn`,
// });

// fcl.config({
//   "app.detail.title": "Flow Mint Page Tutorial", // this adds a custom name to our wallet
//   "app.detail.icon": "https://mydogsnft.com/wp-content/uploads/2023/11/Dog2.png",
//   "accessNode.api": "https://rest-testnet.onflow.org", // this is for the local emulator
//   "discovery.wallet": "https://fcl-discovery.onflow.org/testnet/authn", // this is for the local dev wallet,
//   "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/testnet/authn",
// })

fcl.config({
    "app.detail.title": "Flow Mint Page Tutorial", // this adds a custom name to our wallet
    "app.detail.icon": "https://mydogsnft.com/wp-content/uploads/2023/11/Dog2.png",
    "accessNode.api": "https://rest-mainnet.onflow.org", // this is for the local emulator
    "discovery.wallet": "https://fcl-discovery.onflow.org/authn", // this is for the local dev wallet,
    "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/authn",
  })