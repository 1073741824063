import BreedsTable from '../components/BreedsTable/BreedsTable';

function BreedsPage() {
  return(
    <>
      <h1 className='page-title text-center mb-4'>Breeds DB</h1>
      <BreedsTable />
    </>
  )
}

export default BreedsPage