// import * as fcl from "@onflow/fcl";
// import axios from 'axios';
// import { CreateNFT } from '../cadence/transactions/createNFT'
import axios from 'axios';
import { useState, useEffect } from "react";
import { GenerateSVG } from '../helpers/GenerateSVG'
// import breedsData from './breeds.json';

function EmailButton({certificateData}) {
    const [emailing, setEmailing]   = useState(false)
    const [dogsImage, setDogsImage] = useState('')
    const status = certificateData.mail_status ? 'Resend' : 'Mail'
    const [mailText, setMailText] = useState(status)

    useEffect(() => {
        const img = certificateData.dogs_image
        const convertImageToBase64 = async () => {
            try {
                const response = await fetch(img);
                const blob = await response.blob();
                const reader = new FileReader();
                
                reader.onloadend = () => {
                    const base64data = reader.result;
                    setDogsImage(base64data);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error('Error converting image to base64:', error);
            }
        }

        convertImageToBase64();
    }, [certificateData])


    // const updateData = async (id, address, transactionId) => {
    //     const apiURL = `https://mydogsnft.com/wp-json/nft/v1/update`
    //     const formData = {
    //         address, transactionId, id
    //     }

    //     await axios.post(apiURL, formData, {
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         }
    //       }).then((data) => {
    //       console.log(data)
    //     }).catch((error) => {
    //         console.error("Network error:", error);
    //     });
    // }

    const sendEmail = async (certificateData, dogsImage) => {
        setEmailing(true)
        console.log(certificateData)
        // let breedLink = breedsData.breeds.filter((breed) => breed.name === certificateData.breed_name)
        // breedLink     = (breedLink.length) ? breedLink[0].url : null
        // console.log(breedLink, breedsData.breeds)
        const svg     = GenerateSVG(certificateData, dogsImage)
        const apiURL = `https://mydogsnft.com/wp-json/nft/v1/send-email`

        await axios.post(apiURL, {
                        svg, email: certificateData.email, 
                        firstName: certificateData.first_name,
                        dogsName: certificateData.dogs_name,
                        id: certificateData.id,
                        // breedAmLink: certificateData.breedAmLink || breedLink,
                        breedName: certificateData.breed_name
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((data) => {
                  console.log(data)
                  setMailText('Resend')
                  setEmailing(false)
                }).catch((error) => {
                    console.error("Network error:", error);
                });
            
    }
  
    return (
      (!emailing) ? <button disabled={!certificateData.nftMintedDate} className="btn btn-sm btn-primary text-nowrap" onClick={() => sendEmail(certificateData, dogsImage)}>{mailText}</button> :
               <button className="btn btn-sm btn-primary text-nowrap">Sending ...</button>
    )
  }
  
  export default EmailButton;