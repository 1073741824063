import CertificateForm from '../components/CertificateForm';

function HomePage() {
  return(
    <>
      <CertificateForm/>
    </>
  )
}

export default HomePage