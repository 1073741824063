import Table from 'react-bootstrap/Table';
import {Form} from 'react-bootstrap';
import axios from 'axios';
import './DataTable.css'
import MintButton from '../MintButton.jsx'
import EmailButton from '../EmailButton.jsx'
import { useEffect, useState, useRef } from 'react';
import breedsData from '../breeds.json';
import {IconDate, IconDelete} from './icons'
import SearchTableBox from './SearchTableBox';

function DataTable() {
  let pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [dataHelp, setDataHelp] = useState([]);
  const [classPrev, setClassPrev] = useState('page-item disabled');
  const [classNext, setClassNext] = useState('page-item');
  const [search, setSearch] = useState('');
  const [searchHelp, setSearchHelp] = useState('');
  const [searchColumn, setSearchColumn] = useState(''); // first_name | last_name | country | dogs_name | email | fb_username | breed_name | chip_identifier
  const [upadteData, setUpadteData] = useState(0);
  const [dropActive, setDropActive] = useState(false);
  const [deleteState, setDeleteState] = useState(false);

  // src help
  const srcHelp = `https://mydogsnft.com/wp-json/nft/v1/items/titles?search=${searchHelp}${searchColumn}`;
  // src search
  const srcSearch = `https://mydogsnft.com/wp-json/nft/v1/items?page=${currentPage}&per_page=${pageSize}&search=${search}&${searchColumn}&timestamp=${new Date().getTime()}`
  
  const usersTotal = useRef(1);
  const pagesTotal = useRef(1);

  useEffect(() => {
    setCurrentPage(1);
    setClassPrev('page-item disabled');
    if (pagesTotal.current !== 1) {
      setClassNext('page-item');
    }
  },[search])

  // help drop
  useEffect(() => {
    const getDataHelp= async () => {
      await axios.get(srcHelp)
        .then((data) => {
          let d = data.data.items;
          if (d.length !== 0) {
            setDataHelp(d);
          } else {
            setDataHelp(['no results']);
          }
          if (searchHelp === "") {
            setDropActive(false);
            setDataHelp(d);
          } else {
            setDropActive(true);
          }
        })
        .catch(e => console.log('error: ',e.message))
    } 
    getDataHelp()
  }, [searchHelp])

  useEffect(() => {
    const getData = async () => {
      await axios.get(srcSearch)
        .then((data) => {
          usersTotal.current = data.data.totalCount;
          setDataTable(data.data.items);
          pagesTotal.current = Math.ceil(usersTotal.current / pageSize);
          if (pagesTotal.current === 0 || pagesTotal.current === 1) {
            pagesTotal.current = 1;
            setClassNext('page-item disabled');
            setClassPrev('page-item disabled');
          }
          setDeleteState(false)
        })
        .catch(e => console.log('error: ',e.message))
    } 
    getData()
  }, [currentPage, search, upadteData, deleteState])

  // pagination
  let pages = [];
  for (let i=0; i<pagesTotal.current; i++) {
    pages.push(i+1)
  }

  const handlePageChange = (value) => {
    if (value === 'prev') {
      if (currentPage !== 1) {
        setCurrentPage(last => last - 1);
        if (currentPage !== pagesTotal.current) {
          setClassNext('page-item');
        }
      } else if (currentPage !== 1){
        setClassPrev('page-item disabled');
      }
    } else if (value === 'next') {
      if ((currentPage !== pagesTotal.current)) {
        setCurrentPage(last => last + 1)
        if (currentPage !== 1) {
          setClassPrev('page-item');
        }
      } else {
        setClassNext('page-item disabled');
      }
    } 
    else {
      setCurrentPage(value)
      if (value === 1) {
        setClassPrev('page-item disabled');
        setClassNext('page-item');
      } else if ((value === pagesTotal.current)) {
        setClassNext('page-item disabled');
        setClassPrev('page-item');
      } else {
        setClassNext('page-item');
        setClassPrev('page-item');
      }
    }
  }

  // get the parameters from SearchTableBox component
  const handleSearch = (value, columns) => {
    setSearchColumn(columns);
    setSearchHelp(value);
    if (value === "") setSearch(value);
  }
  const handleClickSearch = (searchText) => {
    setSearch(searchText);
    setDropActive(false)
  }

  // DELETE row
  const handleClickCheck = (e) => {
    let checkboxAll = document.querySelectorAll(".checkbox-cell input[type='checkbox']");
    if (e.target.name === "checkbox-all") {
      if (e.target.checked) {
        checkboxAll.forEach(item => item.checked = true)
      } else {
        checkboxAll.forEach(item => item.checked = false)
      }
    }
  }
  const handleClickDelete = () => {
    let checkboxAll = document.querySelectorAll(".checkbox-cell input[type='checkbox']");
    let ids = [];
    checkboxAll.forEach(item => {
      if (item.checked) {
        ids.push(item.name)
      }
    })
    if (ids.length > 0) {
      axios.delete('https://mydogsnft.com/wp-json/nft/v1/items/delete', {
        params: {"ids": `${ids}`},
      })
      .then(res => {
        console.log(res.data);
        setDeleteState(true)
      })
      .catch(err => console.log(err))
    }
  }

  return(
    <div className="container-fluid table-section">
        <Table striped bordered hover responsive className="table">
          <thead>
            <tr>
              <th>
                <button
                  href='#'
                  className='btn-delete btn-primary active'
                  name='btn-delete'
                  onClick={handleClickDelete}
                >
                  <IconDelete />
                </button>
              </th>
              <th>ID</th>
              <th>Create</th>
              <th>Date Minted</th>
              <th style={{maxWidth: "125px"}}>Email</th>
              <th>Breed Name</th>
              <th>Dogs Image</th>
              <th>Dogs Name</th>
              <th>Address</th>
              {/* <th>Chip Id</th> */}
              <th>FB Username</th>
              <th>Last Name</th>
              <th>First Name</th>
              <th className='col-country'>Country</th>
              {/* <th>Certificate</th> */}
              <th></th>
            </tr>
            <tr>
              <th><input type="checkbox" name="checkbox-all" onChange={handleClickCheck}/></th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>
                <div className="input-group">
                  <input className='form-control' type="text" placeholder="" onChange={handleSearch}/>
                  <span className="input-group-text"><IconDate /></span>
                </div>
              </th>
              <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "email"
                />
              </th>
              <th>
                <Form.Select id="breedName" name='breedName' onChange={handleSearch}>
                  {
                    breedsData.breeds.map(breed => {
                      return <option key={breed.name} value={breed.name}>{breed.name}</option>
                    })
                  }
                </Form.Select>
              </th>
              <th>&nbsp;</th>
              <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "dogsName"
                />
              </th>
              <th>&nbsp;</th>
              {/* <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "chipId"
                />
              </th> */}
              <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "fbUserName"
                />
              </th>
              <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "lastName"
                />
              </th>
              <th>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "firstName"
                />
              </th>
              <th style={{width: '130px'}}>
                <SearchTableBox
                  onChange={handleSearch}
                  onClick={handleClickSearch}
                  data={dataHelp}
                  dropVisible={dropActive}
                  tagName = "country"
                />
              </th>
              {/* <th>&nbsp;</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataTable.map(item => {
              return(
                <tr key={item.id}>
                  <td className='checkbox-cell'>
                    <input type="checkbox" name={item.id} onChange={handleClickCheck}/>
                  </td>
                  <td>{item.id}</td>
                  <td>
                    {
                      (item.nftMintedDate == null) ?
                        <MintButton id={item.id} setUpadteData={setUpadteData} /> :
                        <span className="badge bg-success">Minted</span>
                    }
                  </td>
                  <td>
                    {/* {item.nftMintedDate} */}
                  </td>
                  <td><div className='box-overflow'>{item.email}</div></td>
                  <td>{item.breed_name}</td>
                  <td><a target='_blank' rel='noreferrer' href={item.dogs_image}>Image</a></td>
                  <td><div className="box-overflow">{item.dogs_name}</div></td>
                  <td><div className="box-overflow">{item.nft_address}</div></td>
                  {/* <td>{item.chip_identifier}</td> */}
                  <td><div className="box-overflow">{item.fb_username}</div></td>
                  <td><div className="box-overflow">{item.last_name}</div></td>
                  <td><div className="box-overflow">{item.first_name}</div></td>
                  <td>{item.country}</td>
                  {/* <td><a href="{item.certificate}">Image</a></td> */}
                  <td>
                    <EmailButton certificateData={item}/>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={classPrev}>
            <a className="page-link" href="#" onClick={() => handlePageChange('prev')}>Previous</a>
          </li>
          {
            pages.map((value, i) => {
              if (value === currentPage) {
                return(
                  <li key={i} className="page-item active">
                    <a className="page-link" href="#" onClick={() => handlePageChange(value)}>{value}</a>
                  </li>
                )
              } else {
                return(
                  <li key={i} className="page-item">
                    <a className="page-link" href="#" onClick={() => handlePageChange(value)}>{value}</a>
                  </li>
                )
              }
            })
          }
          <li className={classNext}>
            <a className="page-link" href="#" onClick={() => handlePageChange('next')}>Next</a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default DataTable