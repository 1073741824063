import { Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar.jsx';
import ReactGA from 'react-ga';

// pages
import HomePage from 'pages/Homepage';
import TablePage from 'pages/Tablepage';
import BreedsPage from 'pages/BreedsPage';
import ErrorPage from 'pages/Errorpage';

// import MintComponent from './components/MintComponent.jsx';
// import ShowNfts from './components/ShowNfts';

// Initialize Google Analytics
ReactGA.initialize('G-9EBG43JYFP');

function App() {
  return (
    <div className="App">
        <Navbar />
        <div className="d-none">
          <Link to="/">Home</Link>
          <Link to="/table">Table</Link>
          <Link to="/breeds">Table</Link>
        </div>
        <div className="content-wrapper">
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/table" element={<TablePage />}/>
            <Route path="/breeds" element={<BreedsPage />}/>
            <Route path="*" element={<ErrorPage />}/>
          </Routes>
          {/* <MintComponent /> */}
          {/* <ShowNfts /> */}
        </div>
    </div>
  );
}

export default App;
